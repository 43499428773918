import React, { useContext, useEffect, useMemo, useState } from "react"
import _isEmpty from "lodash/isEmpty"
import _get from "lodash/get"
import _values from "lodash/values"
import _map from "lodash/map"
import Layout from "../components/layout"
import "../styles/new-space.scss"
import SEOHeader from "../components/seo-header"
import "react-multi-carousel/lib/styles.css"
import AuthContext from "../contexts/AuthContext"
import axios from "axios"
import { Link } from "gatsby"
const MyProfile = props => {
  const { user, loginUser } = useContext(AuthContext)
  const renderNonLoggedInContent = () => {
    return (
      <p style={{ textAlign: "center" }}>Please login to view the bookings.</p>
    )
  }

  const renderContent = () => {
    if (_isEmpty(user)) return renderNonLoggedInContent()
    return (
      <div className="row">
        <div className="col-md-12">
          <h4>My Profile</h4>
        </div>

        <div className="col-md-12">
          <p
            style={{
              fontSize: "18px",
              borderBottom: "1px solid rgba(0,0,0,.1)",
            }}
          >
            <span className="fa fa-user"></span> {user?.displayName}
          </p>
          <p
            style={{
              fontSize: "18px",
              borderBottom: "1px solid rgba(0,0,0,.1)",
            }}
          >
            <span className="fa fa-phone"></span> {user?.phoneNumber}
          </p>
          <p
            style={{
              fontSize: "18px",
              borderBottom: "1px solid rgba(0,0,0,.1)",
            }}
          >
            <span className="fa fa-at"></span> {user?.email}
          </p>
          <p
            style={{
              fontSize: "18px",
              borderBottom: "1px solid rgba(0,0,0,.1)",
            }}
          >
            <span className="fa fa-gift"></span> {user?.floatingpoints} Credits
          </p>
          {user?.organizationId === "" || user?.organizationId === null ? (
            <p
              style={{
                fontSize: "18px",
                borderBottom: "1px solid rgba(0,0,0,.1)",
              }}
            >
              <span className="fa fa-share-alt"></span> Referral Code:{" "}
              {user?.referralCode}
            </p>
          ) : (
            ""
          )}{" "}
          <br />
          <br />
          <br />
        </div>
      </div>
    )
  }
  // console.log("user", user)
  return (
    <div>
      <SEOHeader
        title={"My Profile | GoFloaters"}
        description={"My Profile | GoFloaters"}
      ></SEOHeader>

      <Layout>
        <div className="container newspaceDetailContainer">
          {renderContent()}
        </div>
      </Layout>
    </div>
  )
}

export default MyProfile
